<template>
  <div class="container">
    <el-card v-loading='loading'>
      <div slot="header" class="clearfix"><span>首页弹屏广告配置</span></div>
      <div>
        <span class="remarks">上传后，用户进入商城首页将显示弹屏广告，最多上传2张。图片建议尺寸：长：100px；高：50px。</span>
        <el-button type="text" @click='examplesVisible = true'>点击查看示例</el-button>
        <div>
          <div v-for="(t,i) in nemuList" :key='i' class='img-list' @click='handleGetCurrent(i)'>
            <div>图片:</div>
            <div class="img-content" @mouseover.prevent="t.is_hover = true"  @mouseleave.prevent="t.is_hover = false" >
              <div class="mask" v-show="t.is_hover&&t.ImgUrl">
                <i class="el-icon-delete bin" @click.prevent="deleteImage(i)"></i>
              </div>
              <el-upload :action="imgApi" class="avatar-uploader"  :show-file-list="false" :on-success="handleSingleSuccess" list-type="picture-card" style="display: inline-block;marin-left:10px;">
                <div>
                  <img v-show="t.ImgUrl" :src="imgUrl + t.ImgUrl" class="upload-avatar" />
                  <i v-show="!t.ImgUrl" class="el-icon-plus"></i>
                </div>
              </el-upload>
            </div>
            <div>
              <span>链接地址:</span>
              <span class='link-btn' v-show='t.PageName==""' @click="selectPage(i)">请选择链接页面</span>
              <span class='link-btn' v-show='!t.PageName==""' @click="selectPage(i)">{{t.PageName}}</span>
              <span type="text" style='margin-left: 10px;' v-show='!t.PageName==""' class='page-button-error' @click='handleDeletePage(i)'> X </span>
            </div>
            <div class="select-box">
              <span style="margin-right: 10px">推送人群:</span>
              <el-select v-model="t.AudienceType" placeholder="请选择">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.lable" :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button  type="text" class='button-error' @click='handleDeleteItem(i)'>删除</el-button>
          </div>
          <el-upload v-show='nemuList.length<2' class="avatar-uploader uploader" :action="imgApi" :show-file-list="false" :on-success="handleImgSuccess"
            list-type="picture-card" style="display: inline-block;">
           <span class='button-primary'>+增加图片</span>
          </el-upload>
        </div>
      </div>
    </el-card>
    <div class="footer" >
			<el-button style="width:240px;" type="primary" @click="saveSupiler" :loading="btnLoading">保存</el-button>
		</div>
    <!-- 选择的页面弹框 -->
		<link-modal :showLiveTab="true"  :visible="visibleable" @change="dataChange" @cancel="visibleable=false"></link-modal>
    <!-- 示例图 -->
    <el-dialog :visible.sync="examplesVisible" width="400px">
      <img  style="width:300px;object-fit:contain;margin-left: 25px;" src='http://cdn.dkycn.cn/images/melyshop/barrage-ad-img.png'></img>
    </el-dialog>

  </div>
</template>
<script>
import config from '@/config/index';
import linkModal from '@/views/components/linkModal';
import apiList from '@/api/other';
export default {
  components: {
    linkModal
  },
  data(){
    return {
      loading: false,
      imgApi: config.UPLOAD_IMG,
			imgUrl: config.IMG_BASE,
      nemuList:[],
      currrentIndex: 0,
      visibleable: false,
      btnLoading:false,
      examplesVisible: false,
      typeOptions:[
        {
          lable:'全部客户',value: 0
        },
        {
          lable:'新客户',value:1
        },
        {
          lable:'老客户',value:2
        },
        {
          lable:'非会员',value:3
        },
        {
          lable:'会员',value:4
        },
        {
          lable:'推手',value:5
        },
        {
          lable:'非推手',value:6
        },
      ],
    }
  },
  created() {
    this.getData()
  },
  methods: {
  
    async getData(){
      try{
        this.loading = true
        let result = await apiList.playScreenAdvertisingList()
        this.nemuList = result.Result.map(t=>{
          return {
            ...t,
            is_hover: false
          }
        })
      } catch(e){
        this.loading = false
      } finally{
        this.loading = false
      }
    },
    handleImgSuccess(res, file) {
				const isLt2M = file.size / 1024 / 1024 > 2;
				if (isLt2M) {
					alert('上传图片大小不能超过2M!');
					return false;
        }
        this.nemuList.push(
          {
            ImgUrl:res[0],
            is_hover: false,
            PageName: '',
            PagePath: '',
            MenuType: null
          }
        )
				this.$forceUpdate();
    },
    handleSingleSuccess(res, file) {
				const isLt2M = file.size / 1024 / 1024 > 2;
				if (isLt2M) {
					alert('上传图片大小不能超过2M!');
					return false;
        }
        this.nemuList[this.currrentIndex].is_hover = false
        this.nemuList[this.currrentIndex].ImgUrl = res[0]
				this.$forceUpdate();
    },
    //删除图片
    deleteImage(i){
      this.nemuList[i].ImgUrl = ''
    },
    handleGetCurrent(i){
      this.currrentIndex = i
    },
    //删除item
    handleDeleteItem(index){
       this.nemuList.splice(index, 1)
    },
    // 选择链接地址
    selectPage(index) {
      this.visibleable = true
    },
    handleDeletePage(index){
      this.nemuList[index].PageName= ''
      this.nemuList[index].PagePath= ''
      this.nemuList[index].MenuType= null
    },
    dataChange(record) {
      console.log("record",record.type)
        if (record.type == "classify") {
          this.nemuList[this.currrentIndex].PageName = record.data.GroupName
          this.nemuList[this.currrentIndex].MenuType = 1
          this.nemuList[this.currrentIndex].PagePath = 'pages/productGroup/productGroup?id=' + record.data.Id
        } else if (record.type == "functional") {
          this.nemuList[this.currrentIndex].PageName = record.data.name
          this.nemuList[this.currrentIndex].MenuType = 2
          // 全部商品
          if (record.data.id == 1) {
            this.nemuList[this.currrentIndex].PagePath = 'pages/index/index'
          } else if (record.data.id == 2) {
            this.nemuList[this.currrentIndex].PagePath = 'pages/allProduct/allProduct'
          } else if (record.data.id == 3) {
            // 个人中心
            this.nemuList[this.currrentIndex].PagePath = 'pages/personalCenter/mine/mine'
          } else if (record.data.id == 4) {
            // 购物车
            this.nemuList[this.currrentIndex].PagePath = 'pages/shoppingCart/shoppingCart'
          } else if (record.data.id == 5) {
            // 积分中心
            this.nemuList[this.currrentIndex].PagePath = 'pages/pointsCenter/pointsCenter'
          } else if (record.data.id == 6) {
            // 每日签到
            this.nemuList[this.currrentIndex].PagePath = 'pages/userSign/userSign'
          } else if (record.data.id == 7) {
            // 客服
            this.nemuList[this.currrentIndex].PagePath = 'pages/customerService/customerService'
          } else if (record.data.id == 8) {
            // 订单列表
            this.nemuList[this.currrentIndex].PagePath = 'pages/order/myOrder/myOrder'
          } else if (record.data.id == 9) {
            // 分销礼包
            this.nemuList[this.currrentIndex].PagePath = 'pages/invitation/invitationCard/invitationCard'
          }else if (record.data.id == 10) {
            // 成为会员
            this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/openSuccess/openSuccess'
          }else if (record.data.id == 11) {
            // 助力直播列表
            this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/live/live'
          }else if (record.data.id == 12) {
            // 扫码购
            this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/scan/scan'
          }else if (record.data.id == 13) {
            // 充值中心
            this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/balanceRecharge/balanceRecharge'
          }else if (record.data.id == 14) {
            // 群分享
            this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/groupShare/groupShare'
          }else if (record.data.id == 15) {
            // 全部信息页
            this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/information/information'
          }else if (record.data.id == 16) {
            // 短视频带货
            this.nemuList[this.currrentIndex].PagePath = 'pageC/pages/shortVideos/record'
          }else if (record.data.id == 17) {
            // 积分码
            this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/receive-point/receive-point'
          }else if (record.data.id == 18) {
            // 舌诊
            this.nemuList[this.currrentIndex].PagePath = 'pageC/pages/tongueDiagnosis/home'
          }else if (record.data.id == 19) {
						// 企店即创主页
						this.nemuList[this.currrentIndex].PagePath = 'pageC/pages/AIGC/home'
					}
        } else if (record.type == "product") {
          this.nemuList[this.currrentIndex].PageName = record.data.Name
          this.nemuList[this.currrentIndex].MenuType = 3
          this.nemuList[this.currrentIndex].PagePath = 'pages/detail/detail?id=' + record.data.Id
        } else if (record.type == "custom") {
          this.nemuList[this.currrentIndex].PageName = record.data.PageName
          this.nemuList[this.currrentIndex].MenuType = 4
          this.nemuList[this.currrentIndex].PagePath = 'pages/cusPage/cusPage?id=' + record.data.Id
        }else if (record.type == 'truntable'){
          this.nemuList[this.currrentIndex].PageName = record.data.Name
          this.nemuList[this.currrentIndex].MenuType = 6
          this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
        } else if (record.type == 'live'){
          this.nemuList[this.currrentIndex].PageName = record.data.RoomName
          this.nemuList[this.currrentIndex].MenuType = 5
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
        }else if (record.type == 'coupon'){
          this.nemuList[this.currrentIndex].PageName = record.data.CouponName
          this.nemuList[this.currrentIndex].MenuType = 7
          this.nemuList[this.currrentIndex].PagePath = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
        }else if (record.type == 'help') {
          this.nemuList[this.currrentIndex].PageName = record.data.Name
          this.nemuList[this.currrentIndex].MenuType = 8
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
        }else if (record.type == 'group') {
          this.nemuList[this.currrentIndex].PageName = record.data.ProductName
          this.nemuList[this.currrentIndex].MenuType = 9
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
        }else if (record.type == 'questionnaire') {
          this.nemuList[this.currrentIndex].PageName = record.data.Title
          this.nemuList[this.currrentIndex].MenuType = 10
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = 'pageA/pages/questionnaire/questionnaire?&referer=2&id=' + record.data.Id;
        }else if (record.type == 'taketest') {
          this.nemuList[this.currrentIndex].PageName = record.data.Name
          this.nemuList[this.currrentIndex].MenuType = 11
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = `pageC/pages/goodnessTest/home?id=` + record.data.Id
        }else if (record.type == 'contentgroup') {
          this.nemuList[this.currrentIndex].PageName = record.data.GroupName
          this.nemuList[this.currrentIndex].MenuType = 12
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = `pageA/pages/information/informationGroup?id=${record.data.Id}`
        }else if (record.type == 'singlecontent') {
          this.nemuList[this.currrentIndex].PageName = record.data.Title
          this.nemuList[this.currrentIndex].MenuType = 13
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = `pageA/pages/information/detail?id=${record.data.Id}`
        }else if (record.type == 'helpPro') {
          this.nemuList[this.currrentIndex].PageName = record.data.ProductName
          this.nemuList[this.currrentIndex].MenuType = 14
          this.nemuList[this.currrentIndex].OtherData = record
          this.nemuList[this.currrentIndex].PagePath = `pageC/pages/helpPro/helpPro?id=${record.data.ProductId}&activityId=${record.data.ActivityFullId}`
        }
      },
      async saveSupiler(){
        try{
          let i = 0

          this.nemuList.forEach(t=>{
            if(t.ImgUrl==''){
              this.$message({
                type:'error',
                showClose: true,
                message: '请上传弹屏广告图片'
              })
              i++
            }
          })

          if(i > 0){
            return 
          }

          this.nemuList.map((t, i) => {
            if(t.MenuType != 5){
              delete t.OtherData 
            }
            return t
          })

          this.btnLoading = true
          let result = await apiList.playScreenAdvertisingSave({
            PlayScreenAdvertisingList:this.nemuList
          })
          if(result.IsSuccess){
            this.$message({
              type:'success',
              showClose: true,
              message: '保存成功'
            })
          }
        } catch(e){
          this.btnLoading = false
        } finally{
          this.btnLoading = false
        }
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  padding: 10px;
  background-color: #fff;
  .img-list{
    background-color: #F0F2F5;
    margin-top: 10px;
    border-radius: 5px;
    padding: 15px;
    overflow: hidden;
    position: relative;
    .img-content{
      width: 148px;
      height: 148px;
      overflow: hidden;
      margin: -15px auto 20px 80px;
      .mask{
        width: 147px;
        height: 147px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        .bin{
          color: #FFFFFF;
          font-size: 20px;
          position: absolute;
          left: 43%;
          top: 43%;
        }
      }
    }
    .avatar-uploader{
    }
    .upload-avatar {
      width: 145px;
      height: 145px;
      object-fit: contain;
    }
    .page-button-error{
      color:#409EFF;
      font-size:14px;
      cursor:pointer;
    }
    .link-btn{
      margin-left: 10px;
      color:#409EFF;
      font-size:14px;
      cursor:pointer;
    }
    .link-btn:after{
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-top: 1px solid #409EFF;
      border-right: 1px solid #409EFF;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-left:5px;
      margin-bottom:3px;
    }
    
    .button-error{
      position: absolute;
      top: 0;right:20px;
      color:#F56C6C;
      font-size:14px;
    }
  }
  .select-box {
    margin: 10px 0;
  }
  .uploader{
    margin-top:20px;
    ::v-deep .el-upload--picture-card{
      width: 100px;
      height: 30px;
      line-height: 28px;
    }
  }
}
.button-primary{
  color:#409EFF;
  font-size:14px;
}
.remarks{
  color: #909399;
  font-size: 14px;
}
.footer {
  box-shadow: 13px 1px 6px #999;
  line-height: 80px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  z-index: 999;
}
</style>
